// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  contactApi : "https://script.google.com/macros/s/AKfycbw7lwhIvnzBqEi3JN1KuUNL9r6jDyGPGRuRoSsC35d9Ro95yzcYCnytS7OeoAlZ6sqY6w/exec",
  hisashi: 'https://planb-cambodia.com/wp-json/wp/v2/posts?_embed&per_page=3',
  shungo: 'https://avada.theme-fusion.com/wp-json/wp/v2/posts?_embed&per_page=3'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
