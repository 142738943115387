import { Component, OnInit } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
@Component({
	selector: "app-sidebar",
	templateUrl: "./sidebar.component.html",
	styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
	public activeEn = true;
	constructor(private translate: TranslateService) {
		const lang = localStorage.getItem('lang') || 'jp';
		translate.setDefaultLang(lang);
	}
	useLanguage(language: string) {
		localStorage.setItem('lang', language);
		this.translate.use(language);
		
	}
	ngOnInit() {

		// check defult lang button
		if(localStorage.getItem('lang') == 'en') {
			this.activeEn = true;
		}else {
			this.activeEn = false;
		}
		
	}

}