import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-business-header',
	templateUrl: './business-header.component.html',
	styleUrls: ['./business-header.component.scss']
})
export class BusinessHeaderComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
